import React from 'react';
import './Hero.css';
import Header from '../Header/Header';

import { motion } from 'framer-motion';

import CountUp from 'react-countup'


import hero_image3 from '../../assets/hero_image3.png';
 import hero_image_back from '../../assets/hero_image_back.png';
 import calories from '../../assets/calories.png';
 import heart from '../../assets/heart.png';


const Hero = () => {
  const transition = { type: 'spring', duration: 3 };
  const mobile=window.innerWidth<=768? true:false;
  return (
    <>
      <div className="hero" id='home'>
        <div className="blur hero-blur"></div>
        <div className='left-h'>
          <Header />
          {/* {the best ad} */}
          <div className='the-best-ad'>
            <motion.div
              initial={{ left: mobile ? '165px':'238px' }}
              whileInView={{ left: '8px' }}
              transition={{...transition,type:'tween'}}>
            </motion.div>
            <span>The Best Fitness Club in the Town</span>
          </div>

          {/* {Hero heading} */}
          <div className="hero-text">
            <div>
              <span className='stroke-text'>Shape </span>
              <span>Your</span>
            </div>
            <div>
              <span>Ideal Body</span>
            </div>
            <div>
              <span>
                In Here We Will Help You To Shape and Build Your Ideal Body And Live Your Life To Fullest
              </span>
            </div>
          </div>

          {/* {figures} */}
          <div className="figures">
            <div>
              <span>
                 <CountUp end={140} start={100} delay={1} prefix="+" />
              </span>
              <span>expert coaches</span>
            </div>
            <div>
              <span>
              <CountUp end={978} start={800} delay={1} prefix="+" />
              </span>
              <span>member Joined</span>
            </div>
            <div>
              <span>
              <CountUp end={50} start={0} delay={1} prefix="+" />
              </span>
              <span>fitness programs</span>
            </div>
          </div>

          {/* {hero buttons} */}
          <div className="hero-buttons">
            <button className="btn">Get Started</button>
            <button className="btn">Learn More</button>
          </div>
        </div>
        <div className='right-h'>
          <button className='btn'>Join Now</button>

          <motion.div
          initial={{right:"-1rem"}}
          whileInView={{right:"4rem"}}
           transition={transition}
           className="heart-rate">
            <img src={heart} alt='' />
            <span>Heart Rate</span><span> 116 bpm</span>
          </motion.div>

          {/* {hero image} */}
          <img src={hero_image3} alt='' className='hero-image' />
          <motion.img
          initial={{right:'11rem'}}
          whileInView={{right:'20rem'}}
          transition={transition}
           src={hero_image_back} alt='' className='hero-image-back' />

          {/* {calories} */}
          <div className="calories">
            <img src={calories} alt='' />
            <div>
              <span>Calories Burned</span>
              <span> 220 kcal</span>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default Hero;